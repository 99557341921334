import React from "react"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
// import scrollToRef from "../components/scrollToRef"
import {
  Section,
  MarkdownContent,
  Container,
  BreadCrumb,
  Background,
  SectionPageTitle,
  Label,
  RightSidebar, SidebarTitle, SidebarBtnTool,
} from "../components/SectionTags"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/Header"
import Bg from "../components/Image/IndustryHeroBg"
import EmergencyEstimateCTA from "../components/CallToAction/EmergencyEstimateCTA"
import JobNoteIcon from "../components/Icons/JobNoteIcon"
import AddressIcon from "../components/Icons/AddressIcon"
import ClockIcon from "../components/Icons/ClockIcon"
import Twitter from "../components/Icons/Twitter"
import BreakpointUp from "../components/Media/BreakpointUp"
import PrimaryButton from "../components/Button/PrimaryButton"

const MainPanel = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;
  justify-content: space-between;
`
const LeftPanel = styled.div`
  position: relative;
  width: 100%;
  padding: 0 20px;
  ${BreakpointUp.lg`	
		flex: 0 0 58.3333%;
		max-width: 58.3333%;
	`}
`
const RightPanel = styled.div`
  position: relative;
  width: 100%;
  padding: 0 20px;
  ${BreakpointUp.lg`	
		flex: 0 0 41.6667%;
		max-width: 41.6667%;
	`}
`
const JobInfo = styled.div`
  position: relative;
`

const JobInfoItem = styled.div`
  padding: 10px 0;
  display: flex;
  align-items: center;
  .icon{
    width:20px;
    & svg{
      fill:#A1A4A7;
      width: 20px;
    }
    + .text{
      margin-left:15px;
    }
  }
  .text{
    label{
      line-height:20px;
    }
    strong{}
  }
`

class JobTemplate extends React.Component {
  render() {
    const pageData = this.props.data.contentfulJob
    // const applyRef = React.createRef()
    // const applyNav = () => {
    //   scrollToRef(applyRef)
    // }
    return (
      <Layout >
        <Seo title={pageData.metaTitle}
          description={pageData.metaDescription} />
        <div className="sticky-wrapper sticky">
          <Header />
        </div>
        <Section xpt="120px" xpb="60px" mpt="120px" mpb="60px" pt="90px" pb="30px">
          <Background right="0" height="inherit"><Bg /></Background>
          <Container maxWidth="1120px">
            <BreadCrumb>
              <Link to="/"><span>Home</span></Link>
              Job Detail - {pageData.title}
            </BreadCrumb>
            <SectionPageTitle mb='0'>{pageData.title}</SectionPageTitle>
            <Label mb="30px">Posted {pageData.createdAt}</Label>
            <MainPanel>
              <LeftPanel>
                <MarkdownContent
                  dangerouslySetInnerHTML={{
                    __html: pageData.jobDetails.childMarkdownRemark.html,
                  }}
                />
              </LeftPanel>
              <RightPanel>
                <div className="isSticky">
                  <RightSidebar>
                    <SidebarTitle>Important Summary</SidebarTitle>
                    <MarkdownContent
                      dangerouslySetInnerHTML={{
                      __html:
                        pageData.summaryDescription.childMarkdownRemark.html,
                      }}
                    />
                    <JobInfo>
                      <JobInfoItem>
                        <span className="icon"><JobNoteIcon /></span>
                        <span className="text">
                          <Label mb="0">Position</Label>
                          <strong>{pageData.title}</strong>
                        </span>
                      </JobInfoItem>
                      <JobInfoItem>
                        <span className="icon"><AddressIcon /></span>
                        <span className="text">
                          <Label mb="0">Location</Label>
                          <strong>{pageData.location}</strong>
                        </span>
                      </JobInfoItem>
                      <JobInfoItem>
                        <span className="icon">
                          <ClockIcon />
                        </span>
                        <span className="text">
                          <Label mb="0">Job Type</Label>
                          <strong>{pageData.jobType}</strong>
                        </span>
                      </JobInfoItem>
                      <JobInfoItem>
                        <span className="icon">
                          <Twitter />
                        </span>
                        <span className="text">
                          <Label mb="0">Twitter</Label>
                          <strong>{pageData.twitter}</strong>
                        </span>
                      </JobInfoItem>
                    </JobInfo>
                    <SidebarBtnTool>
                      {/* <a onClick={applyNav}>
                        <PrimaryButton text="Apply for this position" />
                      </a> */}
                      {/* { (locations.pathname !== "/job/project-coordinator") &&
                       <>asif</>
                      }      */}
                      <a href="https://recruiting.paylocity.com/recruiting/jobs/All/da9c5e97-8c64-49a3-aaac-4d89309ecf1f/Advanced-Electronic-Services-Inc" target="_blank" rel="noreferrer">
                        <PrimaryButton text="Apply for this position" />
                      </a>
                    </SidebarBtnTool>
                  </RightSidebar>
                </div>
              </RightPanel>
            </MainPanel>
          </Container>
        </Section>
        <EmergencyEstimateCTA
          leftData={pageData.emergencyCta}
          rightData={pageData.estimateCta}
        />
      </Layout>
    )
  }
}

export default JobTemplate

export const pageQuery = graphql`
  query jobPageQuery($id: String!) {
    contentfulJob(id: { eq: $id }) {
      metaTitle
      metaDescription {
        metaDescription
      }
      url
      title
      jobDetails {
        childMarkdownRemark {
          html
        }
      }
      location
      jobType
      twitter
      createdAt(fromNow: true)
      summaryDescription {
        childMarkdownRemark {
          html
        }
      }
      emergencyCta {
        title
        linkText
        image {
          gatsbyImageData(quality: 100)
        }
      }
      estimateCta {
        title
        linkText
        image {
          gatsbyImageData(quality: 100)
        }
      }
    }
  }
`
