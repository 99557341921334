import React from 'react'

const JobNoteIcon = (props) =>(
	<svg xmlns="http://www.w3.org/2000/svg" width="18" height="21.785" viewBox="0 0 18 21.785">
	  <g id="note" transform="translate(-61 -40)">
	    <path id="Path_22018" data-name="Path 22018" d="M70.692,46.508v-.932h2.769V44.2H72.077V40H67.923v4.2H66.538v1.375h2.769v.932H61V61.785H79V46.508Zm-1.385-5.123h1.385V44.2H69.308ZM77.615,60.4H62.385V47.892h6.923v1.385h1.385V47.892h6.923Z" fill="#a1a4a7"/>
	    <path id="Path_22019" data-name="Path 22019" d="M151,271h9.692v1.385H151Z" transform="translate(-85.846 -220.338)" fill="#a1a4a7"/>
	    <path id="Path_22020" data-name="Path 22020" d="M151,331h9.692v1.385H151Z" transform="translate(-85.846 -277.569)" fill="#a1a4a7"/>
	    <path id="Path_22021" data-name="Path 22021" d="M151,391h9.692v1.385H151Z" transform="translate(-85.846 -334.8)" fill="#a1a4a7"/>
	  </g>
	</svg>
)

JobNoteIcon.defaultProps = {
	fill: "#a1a4a7"
}

export default JobNoteIcon